<template>
    
        <Test></Test>
    
</template>

<script>
// @ is an alias to /src
import Test from '@/components/Pasion.vue'

export default {
  name: 'Esencia',
  components: {
    Test
  }
}
</script>

<style lang="scss" scoped>

</style>